import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';

const CatGame = () => {
  const [character, setCharacter] = useState(null);
  const [characterType, setCharacterType] = useState('mouse');
  const [kibbles, setKibbles] = useState([]);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const [highScore, setHighScore] = useState(0);
  const mouseAudioRef = useRef(new Audio('/squeek.mp3'));
  const laserAudioRef = useRef(new Audio('/laser.mp3'));
  const butterflyAudioRef = useRef(new Audio('/flutter.mp3'));
  const backgroundMusicRef = useRef(new Audio('/background-music.mp3'));
  const scoreAudioRef = useRef(new Audio('/score.mp3'));
  const levelUpAudioRef = useRef(new Audio('/level-up.mp3'));
  const containerRef = useRef(null);

  useEffect(() => {
    backgroundMusicRef.current.loop = true;
    const savedHighScore = localStorage.getItem('catGameHighScore');
    if (savedHighScore) {
      setHighScore(parseInt(savedHighScore, 10));
    }
  }, []);

  const createCharacter = useCallback(() => ({
    x: Math.random() * 80 + 10,
    y: Math.random() * 80 + 10,
    speed: Math.max(2000 - level * 200, 500),
  }), [level]);

  const playCharacterSound = useCallback(() => {
    const audio = {
      mouse: mouseAudioRef,
      laser: laserAudioRef,
      butterfly: butterflyAudioRef
    }[characterType];
    audio.current.play().catch(e => console.error(`Errore nel riprodurre l'audio del ${characterType}:`, e));
  }, [characterType]);

  const moveCharacter = useCallback(() => {
    setCharacter(prevChar => ({
      ...prevChar,
      x: Math.random() * 80 + 10,
      y: Math.random() * 80 + 10,
    }));
    playCharacterSound();
  }, [playCharacterSound]);

  useEffect(() => {
    setCharacter(createCharacter());
  }, [createCharacter]);

  useEffect(() => {
    if (character) {
      const interval = setInterval(moveCharacter, character.speed);
      return () => clearInterval(interval);
    }
  }, [character, moveCharacter]);

  const handleInteraction = (event) => {
    event.preventDefault();
    const newScore = score + 1;
    setScore(newScore);
    setKibbles(prevKibbles => [...prevKibbles, { id: Date.now() }]);
    moveCharacter();
    scoreAudioRef.current.play().catch(e => console.error("Errore nel riprodurre l'audio del punteggio:", e));

    if (newScore % 10 === 0) {
      setLevel(prevLevel => prevLevel + 1);
      setCharacter(createCharacter());
      levelUpAudioRef.current.play().catch(e => console.error("Errore nel riprodurre l'audio del livello:", e));
    }

    if (newScore > highScore) {
      setHighScore(newScore);
      localStorage.setItem('catGameHighScore', newScore.toString());
    }
  };

  const toggleMusic = () => {
    if (isMusicPlaying) {
      backgroundMusicRef.current.pause();
    } else {
      backgroundMusicRef.current.play().catch(e => console.error("Errore nel riprodurre la musica:", e));
    }
    setIsMusicPlaying(!isMusicPlaying);
  };

  const handleCharacterChange = (event) => {
    setCharacterType(event.target.value);
    setCharacter(createCharacter());
  };

  const Kibble = useMemo(() => ({ x, y }) => (
    <svg x={x} y={y} width="20" height="20" viewBox="0 0 20 20">
      <ellipse cx="10" cy="10" rx="8" ry="6" fill="#D2691E" />
      <ellipse cx="10" cy="10" rx="6" ry="4" fill="#8B4513" />
    </svg>
  ), []);

  const Mouse = useMemo(() => () => (
    <svg width="150" height="100" viewBox="0 0 150 100">
      <ellipse cx="75" cy="50" rx="70" ry="45" fill="#808080" />
      <ellipse cx="25" cy="20" rx="20" ry="15" fill="#808080" transform="rotate(-30 25 20)" />
      <ellipse cx="125" cy="20" rx="20" ry="15" fill="#808080" transform="rotate(30 125 20)" />
      <circle cx="55" cy="40" r="5" fill="#000000" />
      <circle cx="95" cy="40" r="5" fill="#000000" />
      <ellipse cx="75" cy="55" rx="7" ry="5" fill="#FFC0CB" />
      <line x1="68" y1="55" x2="40" y2="50" stroke="#000000" strokeWidth="1" />
      <line x1="68" y1="57" x2="40" y2="57" stroke="#000000" strokeWidth="1" />
      <line x1="68" y1="59" x2="40" y2="64" stroke="#000000" strokeWidth="1" />
      <line x1="82" y1="55" x2="110" y2="50" stroke="#000000" strokeWidth="1" />
      <line x1="82" y1="57" x2="110" y2="57" stroke="#000000" strokeWidth="1" />
      <line x1="82" y1="59" x2="110" y2="64" stroke="#000000" strokeWidth="1" />
      <path d="M5,50 Q-10,70 5,90" fill="none" stroke="#808080" strokeWidth="5" />
    </svg>
  ), []);

  const LaserPointer = useMemo(() => () => (
    <svg width="50" height="50" viewBox="0 0 50 50">
      <circle cx="25" cy="25" r="20" fill="red" opacity="0.7" />
      <circle cx="25" cy="25" r="10" fill="white" opacity="0.9" />
      <circle cx="25" cy="25" r="5" fill="red" />
    </svg>
  ), []);

  const Butterfly = useMemo(() => () => (
    <svg width="150" height="100" viewBox="0 0 300 200">
      <defs>
        <linearGradient id="wingGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{stopColor:"#BA9BC9", stopOpacity:1}} />
          <stop offset="50%" style={{stopColor:"#9A6AD8", stopOpacity:1}} />
          <stop offset="100%" style={{stopColor:"#662D91", stopOpacity:1}} />
        </linearGradient>
      </defs>
      <path d="M150,100 C120,60 80,30 40,40 C0,50 20,100 40,120 C60,140 120,120 150,100 C180,120 240,140 260,120 C280,100 300,50 260,40 C220,30 180,60 150,100" fill="url(#wingGradient)" />
      <path d="M120,90 C140,50 220,50 240,90" fill="#9A6AD8" opacity="0.7" />
      <path d="M120,110 C140,150 220,150 240,110" fill="#9A6AD8" opacity="0.7" />
      <ellipse cx="150" cy="100" rx="8" ry="40" fill="#662D91" />
      <circle cx="150" cy="60" r="10" fill="#662D91" />
      <path d="M145,55 Q140,40 135,35" stroke="#662D91" strokeWidth="2" fill="none" />
      <path d="M155,55 Q160,40 165,35" stroke="#662D91" strokeWidth="2" fill="none" />
      <circle cx="146" cy="58" r="2" fill="#FFFFFF" />
      <circle cx="154" cy="58" r="2" fill="#FFFFFF" />
    </svg>
  ), []);

  return (
    <div ref={containerRef} className="relative w-full h-screen overflow-hidden touch-none">
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="grass" x="0" y="0" width="120" height="120" patternUnits="userSpaceOnUse">
            <rect fill="#088349" width="120" height="120"/>
            <polygon fill="#23E312" fillOpacity="0.73" points="120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120"/>
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grass)"/>
      </svg>
      
      <div className="absolute top-4 left-4 font-bold text-lg flex flex-col items-start bg-white bg-opacity-75 p-2 rounded-lg">
        <span className="mb-2">Punteggio: {score}</span>
        <span className="mb-2">Livello: {level}</span>
        <span className="mb-2">Record: {highScore}</span>
        <span className="mr-2">Crocchette: {kibbles.length}</span>
        <svg width={(kibbles.length * 25) + 5} height="30">
          {kibbles.map((kibble, index) => (
            <Kibble key={kibble.id} x={index * 25} y={5} />
          ))}
        </svg>
      </div>
      
      <button
        className="absolute top-4 right-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={toggleMusic}
      >
        Musica: {isMusicPlaying ? 'On' : 'Off'}
      </button>

      <select
        className="absolute top-16 right-4 bg-white border border-gray-300 rounded px-2 py-1"
        value={characterType}
        onChange={handleCharacterChange}
      >
        <option value="mouse">Topo</option>
        <option value="laser">Puntatore Laser</option>
        <option value="butterfly">Farfalla</option>
      </select>
      
      {character && (
        <div
          className="absolute cursor-pointer transition-all duration-300 ease-in-out"
          style={{
            left: `${character.x}%`,
            top: `${character.y}%`,
            transform: 'translate(-50%, -50%)',
          }}
          onClick={handleInteraction}
          onTouchStart={handleInteraction}
        >
          {characterType === 'mouse' && <Mouse />}
          {characterType === 'laser' && <LaserPointer />}
          {characterType === 'butterfly' && <Butterfly />}
        </div>
      )}
    </div>
  );
};

export default CatGame;