import React from 'react';
import CatGame from './CatGame';

function App() {
  return (
    <div className="App">
      <CatGame />
    </div>
  );
}

export default App;
